<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import staffService from '@/api/staffService';
import Swal from 'sweetalert2';

export default {
	page: {
		title: 'Users Management',
		meta: [
			{
				name: 'description',
				content: appConfig.description
			}
		]
	},
	components: {
		Layout,
		PageHeader
	},
	computed: {
		userData() {
			return JSON.parse(localStorage.getItem('userData'));
		},
		isAdmin() {
			return this.userData.role_id === 1;
		},
		filteredItems() {
			//If Not Admin only show the current user
			if (!this.isAdmin) {
				return this.tableData.filter((e) => {
					return e.id === this.userData.id;
				});
			} else {
				return this.tableData;
			}
		}
	},
	data() {
		return {
			title: 'Users Management',
			tableData: [],
			totalRows: 1,
			totalPages: 1,
			currentPage: 1,
			perPage: 10,
			pageOptions: [10, 25, 50, 100],
			fields: [
				{
					key: 'name'
				},
				{
					key: 'email'
				},
				{
					key: 'contact'
				},
				{
					key: 'role'
				},
				{
					key: 'action',
					sortable: true
				}
			],
			rolesOptions: [
				{ value: 1, text: 'Admin' },
				{ value: 2, text: 'Agent' },
				{ value: 3, text: 'Verifier' },
				{ value: 4, text: 'Authorizer' },
				{ value: 5, text: 'Installer' },
				{ value: 6, text: 'Lender' }
			]
		};
	},
	methods: {
		init_staffs() {
			staffService.index().then((res) => {
				this.tableData = res.data;
				this.totalRows = res.total;
				this.totalPages = res.last_page;
			});
		},
		deleteUser(id) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'You will not be able to recover this user!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'No, keep it'
			}).then((result) => {
				if (result.isConfirmed) {
					staffService.destroy(id).then(() => {
						Swal.fire(
							'Deleted!',
							'Your user has been deleted.',
							'success'
						);
						this.init_staffs();
					});
				}
			});
		},
	},
	mounted() {
		this.init_staffs();
	}
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" />
		<div class="row">
			<div class="col-xl-4">
				<div class="card bg-primary">
					<a
						v-if="isAdmin"
						href="/users/add"
						class="btn btn-success waves-effect waves-light">
						<i class="mdi mdi-plus-circle me-1"></i>
						Add a new user
					</a>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<!-- Table -->
						<div class="table-responsive mb-0">
							<b-table
								class="table-customer"
								:items="filteredItems"
								:fields="fields"
								responsive="sm"
								:per-page="perPage"
								:current-page="currentPage">
								<template #cell(role)="{ item: { role_id } }">
									{{ rolesOptions[role_id - 1].text }}
								</template>
								<template #cell(action)="{ item: { id } }">
									<ul class="list-inline mb-0">
										<li class="list-inline-item">
											<a
												:href="`/users/add?id=${id}`"
												class="px-2 text-primary"
												><i class="uil uil-pen font-size-18"></i
											></a>
										</li>
										<li class="list-inline-item">
											<a
												@click="deleteUser(id)"
												href="#"
												class="px-2 text-danger"
												><i class="uil uil-trash-alt font-size-18"></i
											></a>
										</li>
									</ul>
								</template>
							</b-table>
						</div>
						<div class="wrap-pagination">
							<div class="row">
								<div class="col">
									<div
										id="tickets-table_length"
										class="dataTables_length">
										<label class="d-flex align-items-center gap-2">
											Show&nbsp;
											<b-form-select
												v-model="perPage"
												size="sm"
												:options="pageOptions"
												@change.native="handlePerPageChange"></b-form-select
											>&nbsp; per page
										</label>
									</div>
								</div>
								<div class="col">
									<div
										class="dataTables_paginate paging_simple_numbers float-end">
										<ul class="pagination pagination-rounded mb-0">
											<!-- pagination -->
											<b-pagination
												v-model="currentPage"
												:total-rows="totalRows"
												:per-page="perPage"></b-pagination>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>
